@media screen and (min-width: 415px) {
  .iconspacing {
    margin-left: 75px;
  }

  .heading-font {
    font-size: 30px !important;
  }
  .small-Font {
    font-size: 12px !important;
    margin-top: 30%;
    margin-bottom: 50%;
  }
}

@media screen and (min-width: 768px) {
  .iconspacing {
    margin-left: 50px;
  }

  .heading-font {
    font-size: 38px !important;
  }

  .small-Font {
    font-size: 12px !important;
    margin-top: 10%;
    margin-bottom: 20%;
  }
}
